
.header {
  display: inline-block;
  margin: 15px 15px;
  .logo {
    display: inline-block;
    .logo-svg {
      width: 150px;
      @include breakpoint(medium) {
        width: 250px;
      }
    }
  }
  .main-menu {
    display: inline-block;
    li>a {
      color: white;
      font-size: 1.25rem;
      font-weight: bold;
    }
    ul ul a {
      color: black;
      font-size: 1rem;
      font-weight: normal;
    }
  }
}

ul li ul li {
  color: black;
}

.header-small {
  float: right;
  padding: 20px;
}

.list-unstyled{
  list-style: none;
}

.grid-container {
  max-width: 100%;
}

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  /* height: 100%; */
  /* -webkit-transition: opacity 0.5s ease, visibility 0.5s ease; */
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}
.background {
  background: url(../img/TCPhoto.jpg);
  background-color: dimgrey;
  overflow: hidden;
  height: 80vh;
  min-height: 530px;
  margin-bottom: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.background-about-us{
  background: url(../img/TC/TC-3.jpg);
  background-color: dimgrey;
  overflow: hidden;
  height: 50vh;
  min-height: 310px;
  margin-bottom: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.background-emp{
  background: url(../img/employees/employeesgroup.jpg);
  background-color: dimgrey;
  overflow: hidden;
  height: 50vh;
  min-height: 310px;
  margin-bottom: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.background-TC{
  background: url(../img/carbon.png);
  overflow: hidden;
  margin-bottom: 30px;
  background-repeat: repeat;
  -webkit-filter: brightness(1.3);
  filter: brightness(1.3);
  @include breakpoint(medium) {
  }
    &:after {
  content: "";
  display: table;
  clear: both;
}
}
.hero-emp {
  display: block;
  margin: auto;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
  font-weight: 600;
  h1 {
    font-weight: 600;
    text-shadow: 3px 2px 10px rgba(0, 0, 0, 0.5);
    line-height: 1.6em;
    margin: 15px auto;
  }
  h3 {
    font-weight: 600;
    text-shadow: 3px 2px 10px rgba(0, 0, 0, 0.5);
  }
}
.hero {

  margin: auto;
  left: 0;
  right: 0;
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  h1 {
    font-weight: 600;
    text-shadow: 3px 2px 10px rgba(0, 0, 0, 0.5);
    line-height: 1.6em;
    margin: 15px auto;
  }
  h3 {
    font-weight: 600;
    text-shadow: 3px 2px 10px rgba(0, 0, 0, 0.5);
  }
&:after {
    content: "";
    display: table;
    clear: both;
}
}

.callout-header {
  margin-top: 100px !important;
  color: white;
}


/*-------------------MOBILE NAV-------------------*/

.mobile-ofc {
  background: transparent;
}

.off-canvas {
  background: $white;
}

.drilldown a {
  background: transparent;
}


/*-------------------END MOBILE NAV-------------------*/


/*-------------------Call outs-------------------*/

.safety {
  background: url(../img/X-Small/IMG_1527-X-Small.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @include breakpoint(medium) {
    background: url(../img/Medium/IMG_1527-Medium.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  @include breakpoint(large) {
    background: url(../img/Large/IMG_1527-Large.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.sign-install {
  background: url(../img/X-Small/IMG_1482-X-Small.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @include breakpoint(medium) {
    background: url(../img/Medium/IMG_1482-Medium.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  @include breakpoint(large) {
    background: url(../img/Large/IMG_1482-Large.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.flagging {
  background: url(../img/X-Small/flagging-X-Small.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @include breakpoint(medium) {
    background: url(../img/Medium/flagging-Medium.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  @include breakpoint(large) {
    background: url(../img/Large/flagging-Large.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.laneclosures {
  background: url(../img/X-Small/lanedrop-X-Small.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 35%;
  @include breakpoint(medium) {
    background: url(../img/Medium/lanedrop-Medium.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 35%;
  }
  @include breakpoint(large) {
    background: url(../img/Large/lanedrop-Large.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 35%;
  }
}

.waterborne {
  background: url(../img/X-Small/waterborne-X-Small.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @include breakpoint(medium) {
    background: url(../img/Medium/waterborne-Medium.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  @include breakpoint(large) {
    background: url(../img/Large/waterborne-Large.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.ipm {
  background: url(../img/X-Small/ipm-X-Small.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @include breakpoint(medium) {
    background: url(../img/Medium/ipm-Medium.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  @include breakpoint(large) {
    background: url(../img/Large/ipm-Large.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.mufa {
  background: url(../img/X-Small/mufa-X-Small.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @include breakpoint(medium) {
    background: url(../img/Medium/mufa-Medium.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  @include breakpoint(large) {
    background: url(../img/Large/mufa-Large.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.rumble-strips {
  background: url(../img/rumblestrips.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.grooving {
  background: url(../img/X-Small/grooving-X-Small.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @include breakpoint(medium) {
    background: url(../img/Medium/grooving-Medium.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  @include breakpoint(large) {
    background: url(../img/Large/grooving-Large.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.thermo {
  background: url(../img/X-Small/thermo-X-Small.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @include breakpoint(medium) {
    background: url(../img/Medium/thermo-Medium.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  @include breakpoint(large) {
    background: url(../img/Large/thermo-Large.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.removals {
  background: url(../img/X-Small/removals-X-Small.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @include breakpoint(medium) {
    background: url(../img/Medium/removals-Medium.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  @include breakpoint(large) {
    background: url(../img/Large/removals-Large.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.barrierwall {
  background: url(../img/X-Small/barrierwall2-X-Small.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @include breakpoint(medium) {
    background: url(../img/Medium/barrierwall2-Medium.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  @include breakpoint(large) {
    background: url(../img/Large/barrierwall2-Large.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.con-barrierwall {
  background: url(../img/X-Small/concrete-wall-X-Small.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 60%;
  @include breakpoint(medium) {
    background: url(../img/Medium/concrete-wall-Medium.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 60%;
  }
  @include breakpoint(large) {
    background: url(../img/Large/concrete-wall-Large.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 60%;
  }
}

.preform {
  background: url(../img/X-Small/preform-X-Small.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @include breakpoint(medium) {
    background: url(../img/Medium/preform-Medium.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  @include breakpoint(large) {
    background: url(../img/Large/preform-Large.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.delineator {
  background: url(../img/delineator.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.sweeping {
  background: url(../img/X-large/Truck3-X-Large.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.snowPlowing {
  background: url(../img/snowPlowImg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 75%;
}

.CS {
  background: url(../img/employee.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.callout-text {
  color: white;
  width: 100%;
  font-size: 1.2rem;
}

.callout {
  overflow: hidden;
  width: 100%;
  text-align: center;
  max-height: 342px;
  min-height: 342px;
  h3 {
    text-shadow: 3px 2px 10px rgba(0, 0, 0, 0.8);
  }
  p {
    color: transparent;
  }
}
.hvr-bounce-to-top-start{
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  &:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 112, 52, 0.9);
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
    
  }
}
.hvr-bounce-to-top {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  &:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
    background: rgba(255, 112, 52, 0.9);
    
  }
  &:hover,
  &:focus,
  &:active {
    color: white;
    p {
      color: white;
      text-shadow: 3px 2px 10px rgba(0, 0, 0, 0.5);
      z-index: 2;
      font-size: 1.1rem;
    
      @include breakpoint(medium) {
        font-size:1.2rem
      }
    }
    .callout-header {
      transition-duration: 0.5s;
      margin-top: 5px !important;
      @include breakpoint(medium) {
        margin-top: 30px;
      }
    }
  }
  &:hover:before,
  &:focus:before,
  &:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  }
}
.more-info{
  position: absolute;
  right:15px;
  bottom: 10px;
  border: 2px solid white !important;
  border-radius: 3px;
  font-weight: bold;
  background: transparent;
  
}
.more-info.button:hover, .button:focus {
     background-color: transparent;
}
.more-info-2{
  position: absolute;
  right:15px;
  bottom: 10px;
  border: 2px solid black !important;
  border-radius: 3px;
  font-weight: bold;
  background: transparent;
  color: black;
  
}


.sales-photo{
  background: url(../img/arrow-board.jpg) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: '';
}
.sales-photo-safety-ap{
  background: url(../img/safety-ap.jpg) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-rollup{
  background: url(../img/rollup.png) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-sign{
  background: url(../img/sign.png) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-cone{
  background: url(../img/cone.png) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-trimline{
  background: url(../img/trimline.jpg) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-bracket{
  background: url(../img/bracket.jpg) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-crash{
  background: url(../img/crash.jpg) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-message{
  background: url(../img/message-board.jpg) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-signal{
  background: url(../img/signal.png) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-lighttw{
  background: url(../img/lighttw.png) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-marking-paint{
  background: url(../img/marking-paint.jpg) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-parkit{
  background: url(../img/parkit.jpg) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-easyrider{
  background: url(../img/easyrider.png) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-lights{
  background: url(../img/lights.png) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-graco{
  background: url(../img/graco.png) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-airport{
  background: url(../img/airport.jpg) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-dib{
  background: url(../img/DIB.png) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-fence{
  background: url(../img/chainlink.jpeg) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-barricades{
  background: url(../img/type3.png) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-marker{
  background: url(../img/marker.png) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-kwall{
  background: url(../img/concrete.png) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-wwall{
  background: url(../img/waterwall.png) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-caution{
  background: url(../img/caution.png) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-stop-slow{
  background: url(../img/stop-slow.png) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-bikerack{
  background: url(../img/bikerack.jpeg) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}
.sales-photo-deliniator{
  background: url(../img/deliniator.png) no-repeat;
  min-height: 300px;
  background-size: contain;
  background-position: center;
  content: ''; 
}

.sales-info {
  color: black;
  
  & h3{
    text-shadow: none;
    color: black;
    font-size: 1rem;
    font-weight: 600;
  }
  
 & p{
  color: black;
}
  @include breakpoint(xlarge) {
    margin-top: 50px;
      & h3{
        font-size: 1.9375rem;
        
  }
  }
}
/*-------------------End Call outs-------------------*/


/*-------------------BANNER SECTION-------------------*/

.banner-section-main {
  background: url(../img/Main.jpg);
}

.banner-section-tc {
  background: url(../img/Medium/flagging-Medium.jpg);
}

.banner-section-pm {
  background: url(../img/PM.jpg);
}

.banner-section-event {
  background: url(../img/event.jpg);
}

.banner-section-ss {
  background: url(../img/snowPlowImg.png);
}

.banner-section-rental {
  background: url(../img/rental.jpg);
  background-position: center 80% !important;
}

.banner-section-main,
.banner-section-tc,
.banner-section-pm,
.banner-section-ss,
.banner-section-event,
.banner-section-rental {
  position: relative;
  height: 400px;
  overflow: hidden;
  background-size: cover;
  margin-bottom: 30px;
  background-position: center center;
  text-shadow: 3px 2px 10px rgba(0, 0, 0, 0.5);
  line-height: 1.6em;
  text-align: center;
  .banner-header {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 30%;
    -moz-transform: translate(0, -30%);
    -ms-transform: translate(0, -30%);
    -o-transform: translate(0, -30%);
    -webkit-transform: translate(0, -30%);
    transform: translate(0, -30%);
    color: #ffffff;
    text-align: center;
    font-weight: 600;
    h2 {
      font-weight: 600;
    }
    p {
      font-size: 1.3rem;
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
  }
}


/*-------------------END BANNER SECTION-------------------*/


/*-------------------Slide Show-------------------*/

#slideshow {
  overflow: hidden;
  max-height: 342px;
  min-height: 342px;
  margin: 0 auto;
}

.slide-wrapper {
  overflow: hidden;
  width: 400%;
  -webkit-animation: slide 18s ease infinite;
}

.slide {
  width: 25%;
  float: left;
  max-height: 342px;
  min-height: 342px;
}

.slide:nth-child(1) {
  background: url(../img/X-Small/TC-4-X-Small.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @include breakpoint(medium) {
    background: url(../img/Medium/TC-4-Medium.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  @include breakpoint(large) {
    background: url(../img/Large/TC-4-Large.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.slide:nth-child(2) {
  background: url(../img/X-Small/TC-12-X-Small.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @include breakpoint(medium) {
    background: url(../img/Medium/TC-12-Medium.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  @include breakpoint(large) {
    background: url(../img/Large/TC-12-Large.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.slide:nth-child(3) {
  background: url(../img/X-Small/TC-3-X-Small.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @include breakpoint(medium) {
    background: url(../img/Medium/TC-3-Medium.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  @include breakpoint(large) {
    background: url(../img/Large/TC-3-Large.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.slide:nth-child(4) {
  background: url(../img/X-Small/TC-11-X-Small.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 85%;
  @include breakpoint(medium) {
    background: url(../img/Medium/TC-11-Medium.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 85%;
  }
  @include breakpoint(large) {
    background: url(../img/Large/TC-11-Large.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 85%;
  }
}

.slide-number {
  margin-top: 80px !important;
  color: white;
  width: 100%;
  position: absolute;
  text-align: center;
}

@-webkit-keyframes slide {
  20% {
    margin-left: 0px;
  }
  30% {
    margin-left: -100%;
  }
  50% {
    margin-left: -100%;
  }
  60% {
    margin-left: -200%;
  }
  70% {
    margin-left: -200%;
  }
  80% {
    margin-left: -300%;
  }
  90% {
    margin-left: -300%;
  }
}


/*-------------------END Slide Show-------------------*/


/*-------------------Rental Items-------------------*/

.steel-plate {
  background: url(../img/steelplate.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  min-height: 100px;
}

.barricades {
  background: url(../img/type3.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  min-height: 100px;
}

.waterwall {
  background: url(../img/waterwall.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  min-height: 100px;
}

.barrels {
  background: url(../img/barrels.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  min-height: 100px;
}

.trimlines {
  background: url(../img/trimline.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  min-height: 100px;
}

.arrow-board {
  background: url(../img/arrow-board.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  min-height: 100px;
}

.message-board {
  background: url(../img/message-board.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  min-height: 100px;
}

.tma {
  background: url(../img/tma.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  min-height: 100px;
}

.chainfence {
  background: url(../img/chainlink.jpeg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  min-height: 100px;
}

.bikerack {
  background: url(../img/bikerack.jpeg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  min-height: 100px;
}


/*-------------------END Rental Items-------------------*/
/*-------------------employee card -------------------*/

.cards {
text-align: right;
  padding-right: 10px;
}

.card-name-first{
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-size: 1.5rem;
  display: inline-block;
}
.card-name-last{
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 2rem;
  display: inline-block;
  padding-right: 5%;
}
.card-info{
}
.card-title{
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 1.5rem;
  color: $orange;
  padding-right: 10%
}
.card-info-list{
  list-style: none;
  & li {
    padding-top: 20px;
  }
  & span {
    margin-right: 10px;
  }
}

.btn-emp{
    width: 100%;
  text-align: center;
  height:150px;
  background-color: coral;
  font-size: 90px;
  color: aliceblue;
}

#il-btn{background-color: aqua; width: 100%;}
#mo-btn{background-color:cadetblue; width: 100%;}
#tc-btn{background-color:cyan; width: 100%;}
#pm-btn{background-color:darkturquoise; width: 100%;}

.sales-name{
  padding-right: 35%;
}

.hide{
  display: none;
}
/*-------------------END employee card -------------------*/
/*-------------------Employee Photo -------------------*/

.janea-photo{

  background: url(../img/employees/janead.jpg) no-repeat;
  background-size: cover;
  background-position: center;
    width: 100%;
    height: 0;
    padding-top: 66.64%; 
  overflow: hidden;
  text-align: center;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
}
.bj-photo{

  background: url(../img/employees/bjp.jpg) no-repeat;
  background-size: cover;
  background-position: center;
    width: 100%;
    height: 0;
    padding-top: 66.64%; 
  overflow: hidden;
  text-align: center;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
}
.cameron-photo{

  background: url(../img/employees/camerond.jpg) no-repeat;
  background-size: cover;
  background-position: center;
    width: 100%;
    height: 0;
    padding-top: 66.64%; 
  overflow: hidden;
  text-align: center;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
}
.chris-photo{

  background: url(../img/employees/chrisc.jpg) no-repeat;
  background-size: cover;
  background-position: center;
    width: 100%;
    height: 0;
    padding-top: 66.64%; 
  overflow: hidden;
  text-align: center;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
}
.debbie-photo{

  background: url(../img/employees/debbieo.jpg) no-repeat;
  background-size: cover;
  background-position: center;
    width: 100%;
    height: 0;
    padding-top: 66.64%; 
  overflow: hidden;
  text-align: center;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
}
.diane-photo{

  background: url(../img/employees/dianes.jpg) no-repeat;
  background-size: cover;
  background-position: center;
    width: 100%;
    height: 0;
    padding-top: 66.64%; 
  overflow: hidden;
  text-align: center;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
}
.fred-photo{

  background: url(../img/employees/fredm.jpg) no-repeat;
  background-size: cover;
  background-position: center;
    width: 100%;
    height: 0;
    padding-top: 66.64%; 
  overflow: hidden;
  text-align: center;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
}
.jacki-photo{

  background: url(../img/employees/jackit.jpg) no-repeat;
  background-size: cover;
  background-position: center;
    width: 100%;
    height: 0;
    padding-top: 66.64%; 
  overflow: hidden;
  text-align: center;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
}
.joe-photo{

  background: url(../img/employees/joeb.jpg) no-repeat;
  background-size: cover;
  background-position: center;
    width: 100%;
    height: 0;
    padding-top: 66.64%; 
  overflow: hidden;
  text-align: center;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
}
.john-photo{

  background: url(../img/employees/johnb.jpg) no-repeat;
  background-size: cover;
  background-position: center;
    width: 100%;
    height: 0;
    padding-top: 66.64%; 
  overflow: hidden;
  text-align: center;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
}
.kerri-photo{

  background: url(../img/employees/kerrit.jpg) no-repeat;
  background-size: cover;
  background-position: center;
    width: 100%;
    height: 0;
    padding-top: 66.64%; 
  overflow: hidden;
  text-align: center;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
}
.lance-photo{

  background: url(../img/employees/lancem.jpg) no-repeat;
  background-size: cover;
  background-position: center;
    width: 100%;
    height: 0;
    padding-top: 66.64%; 
  overflow: hidden;
  text-align: center;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
}
.mike-photo{

  background: url(../img/employees/mikea.jpg) no-repeat;
  background-size: cover;
  background-position: center;
    width: 100%;
    height: 0;
    padding-top: 66.64%; 
  overflow: hidden;
  text-align: center;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
}
.paul-photo{

  background: url(../img/employees/pault.jpg) no-repeat;
  background-size: cover;
  background-position: center;
    width: 100%;
    height: 0;
    padding-top: 66.64%; 
  overflow: hidden;
  text-align: center;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
}
.rick-photo{

  background: url(../img/employees/ricka.jpg) no-repeat;
  background-size: cover;
  background-position: center;
    width: 100%;
    height: 0;
    padding-top: 66.64%; 
  overflow: hidden;
  text-align: center;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
}
.ron-photo{

  background: url(../img/employees/ronj.jpg) no-repeat;
  background-size: cover;
  background-position: center;
    width: 100%;
    height: 0;
    padding-top: 66.64%; 
  overflow: hidden;
  text-align: center;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
}
.sheri-photo{

  background: url(../img/employees/sherib.jpg) no-repeat;
  background-size: cover;
  background-position: center;
    width: 100%;
    height: 0;
    padding-top: 66.64%; 
  overflow: hidden;
  text-align: center;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
}
.cards-start{
  margin-top: 100px;
}


.card-border{border: 1px solid black !important;
border-radius: 10px;
margin-top: 5vh;}
/*-------------------END Employee Photo -------------------*/
/*-------------------footer-------------------*/

footer {
  margin-top: 50px !important;
  padding-top: 10px;
  border-top: 1px dashed silver;
}

footer hr {
  border-top: none;
  border-bottom: 1px solid #666;
}

footer a:hover {
  color: #f26522;
}

footer h5 {
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #ff7034;
  border-bottom: 1px solid #464646;
  padding-bottom: 10px;
}

footer h5 span {
  color: #939191;
}
/*  SOCIAL BUTTONS  */

.social {
    width: 25%;
    float: left;
}

.sbtn {
    width: 50px;
  float: left;
  white-space:nowrap;
  font-size:.8em;
  display:inline-block;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.35);
  margin:2px;
  -webkit-transition:all .5s;
  -moz-transition:all .5s;
  transition:all .5s;
  overflow:hidden;
}

.sbtn:hover {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.45);
}

.sbtn:focus {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.4);
}

.sbtn-icon > i {
  float:left;
  -webkit-transition:all .5s;
  -moz-transition:all .5s;
  transition:all .5s;
  line-height:50px;

    
}


.sbtn-icon > i {
  position:relative;
  width:100%;
  text-align:center;
  font-size:24px;
  color:#fff;
  background:transparent;
   -webkit-text-stroke: 1px black;
   text-shadow:
       3px 3px 0 #000,
     -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
       1px 1px 0 #000;
}


.sbtn-icon:hover > i,.sbtn-icon:focus > i {
  color:#FFF;
  -webkit-text-stroke: 0px black;
    text-shadow:
       3px 3px 0 #000;
}


/*Facebook*/
.sbtn-facebook:hover > i,.sbtn-facebook:focus > i {
  color:#3b5998;
    -webkit-text-stroke: 0px black;
    text-shadow:
       3px 3px 0 #000;
}


/*Twitter*/
.sbtn-twitter:hover > i,.sbtn-twitter:focus > i {
  color:#55acee;
    -webkit-text-stroke: 0px black;
    text-shadow:
       3px 3px 0 #000;
}



/*Youtube*/
.sbtn-Youtube:hover > i,.sbtn-Youtube:focus > i {
  color:#cb2028;
    -webkit-text-stroke: 0px black;
    text-shadow:
       3px 3px 0 #000;
}


/*LinkedIn*/
.sbtn-linkedin:hover > i,.sbtn-linkedin:focus > i {
  color:#007bb6;
    -webkit-text-stroke: 0px black;
    text-shadow:
       3px 3px 0 #000;
}

/*-------------------END FOOTER-------------------*/

/* font-face */
@import url(https://fonts.googleapis.com/css?family=Roboto);
/* variables */
/* default style */
/* common style */
* {
  margin: 0;
  padding: 0;
}
*,
*:after,
*:before {
  box-sizing: border-box;
}
* {
  outline: none;
}
::-webkit-scrollbar {
  display: none;
}
/* */
.container-select{
  position: absolute;
  right:20px;
}

.selected-item {
  margin: 20px 0;
}
/* custom select style */
.cusSelBlock {
  height: 50px;
  min-width: 250px;
}
#cusSelectbox {
  height: 100%;
  width: 100%;
}
.s-hidden {
  visibility: hidden;
}
.cusSelBlock {
  display: inline-block;
  position: relative;
  -webkit-perspective: 800px;
  perspective: 800px;
}
.selectLabel {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  background-color: #fff;
  border: 1px solid #333;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  color: #333;
  cursor: pointer;
  display: block;
  height: 100%;
  width: 100%;
  letter-spacing: 2px;
  line-height: 50px;
  padding: 0 50px 0 20px;
  text-align: left;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transition: -webkit-transform 300ms;
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.selectLabel:after {
  content: '\25BC';
  border-left: 1px solid #333;
  color: #333;
  font-size: 12px;
  line-height: 17px;
  padding: 10px;
  text-align: center;
  position: absolute;
  right: 0px;
  top: 15%;
  height: 70%;
  width: 50px;
}
.selectLabel:active {
  -webkit-transform: rotateX(30deg);
  transform: rotateX(30deg);
}
.selectLabel:active:after {
  content: '\25B2';
}
.selectLabel.active:after {
  content: '\25B2';
}
::-webkit-scrollbar {
  display: none;
}
.options {
  position: absolute;
  top: 50px;
  height: 1px;
  width: 100%;
  margin-left: 0;
}
.options li {
  background-color: #ffffff;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
  border-bottom: 1px solid #333;
  cursor: pointer;
  display: block;
  line-height: 50px;
  list-style: none;
  opacity: 1;
  padding: 0 50px 0 20px;
  text-align: left;
  -webkit-transition: -webkit-transform 300ms ease;
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
  position: absolute;
  top: -50px;
  left: 0;
  z-index: 0;
  height: 50px;
  width: 100%;
}
.options li:hover,
.options li.active {
  background-color: #333;
  color: #fff;
}
.options li:nth-child(1) {
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
  z-index: 6;
}
.options li:nth-child(2) {
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
  z-index: 5;
}
.options li:nth-child(3) {
  z-index: 4;
}
.options li:nth-child(4) {
  z-index: 3;
}
.options li:nth-child(5) {
  z-index: 2;
}
.options li:nth-child(6) {
  z-index: 1;
}
.selected-item{
  display: none;
}
.il-emp{
  &:before{
    clear: both;
  }
}
